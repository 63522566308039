import './steamApp.css';
import React, { useState, useEffect } from 'react';

function SteamApp() {

  const [playerData, setData] = useState({});
  const [gameData, setGameData] = useState({});
  const [smite, setSmite] = useState({});

  useEffect(() => {
    console.log('i fire once');
    fetch('https://api.loomer.dev/steamPlayer')
      .then((response) => response.json())
      .then((data) => {
        let obj = data;
        setData(data.response.players)
        Object.keys(data.response.players).forEach((user) => {
          fetch(`https://api.loomer.dev/player?id=${data.response.players[user].steamid}`)
            .then((response) => response.json())
            .then((data) => {
              gameData[obj.response.players[user].personaname] = data.response
              setGameData({ ...gameData })

            })
            .catch((err) => {
              console.log(err.message);
            });
        })
      })
    fetch(`https://api.loomer.dev/smiteapi`)
      .then((response) => response.json())
      .then((data) => {
        setSmite(data)
        console.log(data)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  let gamd = (string) => {
    if (gameData[string]) {
      return (Object.keys(gameData[string].games).map((game) => {
        let gme = gameData[string].games[game];
        const src = `https://media.steampowered.com/steamcommunity/public/images/apps/${gme.appid}/${gme.img_icon_url}.jpg`;
        return (

          <div className="gameDiv" key={gme.id}>
            <div className="image"><img src={src} alt="avatar" /></div>
            <div className="gname">{gme.name.substring(0, 21)} {gme.name.length >= 21 && '...'}</div>
            <div className="gplay2">Last 2 Weeks: {Math.floor(gme.playtime_2weeks / 60)} Hrs</div>
            <div className="gplayfull">Forever: {Math.floor(gme.playtime_forever / 60)} Hrs</div>
          </div>
        )
      }
      ))
    } else {
      return (<div>hi</div>)
    }
  }

  let smiteFunc = (steamid) => {
    if (smite[steamid]) {
      let smte = smite[steamid].smite;
      return (
        <div className="smiteDivInner">
          <div className="smiteLabel">Smite</div>
          <div className="smiteAvatar">{<img src={smte.player[0].Avatar_URL} alt="avatar" height="75px" width="75px"/>}</div>
          <div className="smiteName">{smte.player[0].Name}</div>
          <div className="smiteStatus">{smte.status[0].status_string}</div>
          <div className="smiteMessage">{smte.player[0].Personal_Status_Message}</div>
          <div className="smiteHours">{smte.player[0].HoursPlayed} Hrs Played</div>
          <div className="smiteWinLoss">{smte.player[0].Wins} Matches Won</div>
          <div className="smiteLastMatch">
            <div className="smiteInfo">Last Match:</div>
            <div className="smiteLGod">{smte.match[0].God}</div>
            <div className="smiteLKD">Kills: {smte.match[0].Kills} Deaths: {smte.match[0].Deaths}</div>
            <div className="smiteLWin">{smte.match[0].Win_Status}</div>
            {(smte.match[0].Win_Status === "Loss" && smte.match[0].Surrendered === 1) ?
              <div className="smiteSurr">"Surrendered"</div> : ""}
            {(smte.match[0].Active_1.includes("Meditation") || smte.match[0].Active_2.includes("Meditation")) ?
              <div className="smiteMed">Did NOT buy Meditation</div> : ""}
          </div>
        </div>
      )
    } else {
      return (<div>hi</div>)
    }
  }

  return (
    <div className="App">
      {Object.keys(playerData).map((user) => {
        let string = playerData[user].personaname
        //TODO figure out why you cant pull the data
        let time = new Date(playerData[user].lastlogoff * 1000).toDateString()
        return (<div className="SteamUser" key={playerData[user].personaname}>
          <div className="avatar"><img src={playerData[user].avatarfull} alt="avatar" height="125px" width="125px"/></div>
          <div className="personaName">{playerData[user].personaname}</div>
          <div className="lastlogoff">Last Played: {time}</div>
          {playerData[user].gameextrainfo &&
            <div className="currentGame">Currently Playing: {playerData[user].gameextrainfo}</div>}

          <div className="games">{gamd(string)}</div>
          <div className="smiteDiv">{smiteFunc(playerData[user].steamid)}</div>
        </div>
        )
      }
      )
      }
    </div>
  );
}

export default SteamApp;
