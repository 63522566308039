import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import SteamApp from './SteamApp';
import Meditation from './pages/meditation';
import Layout from "./pages/layout";

//<React.StrictMode>
//</React.StrictMode>
//working
export default function App() {
    return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="meditation" element={<Meditation />} />
            <Route path="steam" element={<SteamApp />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }


  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);

