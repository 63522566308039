import React, { useState, useEffect } from 'react';
import '../css/meditation.css'
import top_med from '../assets/meditation_home.png'
import med_dark from '../assets/meditation_dark.png'
import selfish from '../assets/med_selfish.png'
import teamplayer from '../assets/med_teamplayer.png'
import selfish_dark from '../assets/med_selfish_darken.png'
import teamplayer_dark from '../assets/med_teamplayer_darken.png'
import { Helmet } from "react-helmet";

function Meditation() {

    const [userInputName, setUserInputName] = useState([]);
    const [userData_API, setUserData_API] = useState({});
    const [userDataExists, setUserDataExists] = useState(false);
    const [resultScreen, setResultScreen] = useState(false);
    const [matchData_API, setMatchData_API] = useState({});

    //TODO: instead of fadeover, can we fade the active screen away?
    const [fadeState, setfadeState] = useState(false);

    const [playerDoesntExist, setPlayerDoesntExist] = useState(false);

    function reset() {
        setUserInputName([])
        setUserData_API({})
        setUserDataExists(false)
        setResultScreen(false)
        setMatchData_API({})
        setfadeState(false)
    }


    const mediation_IDs = [21479, 21510, 21570, 21571]

    useEffect(() => {
        //Run after user is inputted and user hits enter
        if (userInputName[1]) {
            fetch(`https://api.loomer.dev/smiteplayerid?name=${userInputName}`)
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data !== "Not a Player") {
                        setUserData_API(data)
                        setUserDataExists(true)

                        fetch(`https://api.loomer.dev/smiteplayermatch?id=${data.id}`)
                            .then((response) => {
                                return response.json()
                            })
                            .then((data) => {
                                let assaultMatches = data.filter((as) => as.Queue === "Assault")
                                let noMedMatches = assaultMatches.filter((med) =>
                                    !(mediation_IDs.includes(med.ActiveId1) || mediation_IDs.includes(med.ActiveId2)))
                                //6 second delay
                                const delayBeforeResultsScreen = (6 * 1000)
                                if (Math.floor((noMedMatches.length / assaultMatches.length) * 100) <= 50) {
                                    //mostly med 
                                    console.log(resultScreen)
                                    setTimeout(() => setResultScreen(1), delayBeforeResultsScreen);
                                } else {
                                    setTimeout(() => setResultScreen(2), delayBeforeResultsScreen);
                                }
                                setMatchData_API(data)
                                setTimeout(() => setfadeState(true), (delayBeforeResultsScreen + (2 * 1000)));
                            })
                    } else {
                        setPlayerDoesntExist(true)
                        setTimeout(() => setPlayerDoesntExist(false), 2000);
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [userInputName]);

    const ConfirmationBox = () => {
        //Displays the user data pulled when confirming user
        return (<div className="outer">
            {(userData_API.name) ? <div className="inner"><div className="confirmtitle">This is You?</div>
                <div className="userStuff"><div className={(userData_API.avatar) ? "avatar" : "disable"}><img src={userData_API.avatar} alt="No Avatar" /></div>
                    <div className="userText"><div className="name">Name: {userData_API.name}</div>
                        <div className="creation">Created: {userData_API.creation}</div></div></div>
            </div> : ""}
        </div>
        )
    }

    const wordsArr = ["Pathetic...", "Interesting", "These stats aren't suprising...", "Let the Review Commence"]
    //TODO: Combine confirmation + pathetic
    function PatheticBox() {
        return (<div>
            {(userData_API.name) ? <div>
                <div className="sad">{wordsArr[Math.floor(Math.random() * wordsArr.length)]}</div>
            </div> : ""}
        </div>
        )
    }

    const UserDetail = () => {
        //sets up the review data
        if (matchData_API.length > 1) {
            let assaultMatches = matchData_API.filter((as) => as.Queue === "Assault")
            let AssaultCount = assaultMatches.length;
            let meditationMatches_1 = assaultMatches.filter((med) => (mediation_IDs.includes(med.ActiveId1)))
            let MedMatches_1_SelfHeal = meditationMatches_1.reduce((total, curr) => total + curr.Healing_Player_Self, 0)
            let MedMatches_1_OtherHeal = meditationMatches_1.reduce((total, curr) => total + curr.Healing, 0)

            let meditationMatches_2 = assaultMatches.filter((med) => (mediation_IDs.includes(med.ActiveId2)))
            let MedMatches_2_SelfHeal = meditationMatches_2.reduce((total, curr) => total + curr.Healing_Player_Self, 0)
            let MedMatches_2_OtherHeal = meditationMatches_2.reduce((total, curr) => total + curr.Healing, 0)

            let noMedMatches = assaultMatches.filter((med) => !(mediation_IDs.includes(med.ActiveId1) || mediation_IDs.includes(med.ActiveId2)))
            let noMed_SelfHeal = noMedMatches.reduce((total, curr) => total + curr.Healing_Player_Self, 0)
            let noMed_OtherHeal = noMedMatches.reduce((total, curr) => total + curr.Healing, 0)
            let noMedWins = noMedMatches.filter((match) => (match.Win_Status.includes("Win")))

            let Med_1Wins = meditationMatches_1.filter((match) => match.Win_Status.includes("Win"))
            let Med_2Wins = meditationMatches_2.filter((match) => match.Win_Status.includes("Win"))
            return (<div className="outerwrapper"><div className="wrapper">
                {(resultScreen) ? <div>
                    <div className="reviewName">{userData_API.name}</div>
                    <br />
                    <div>Number of Recent Matches: {matchData_API.length}</div>
                    <div>Number of Assaults Reviewed: {AssaultCount}</div>
                    <br></br>
                    <div>In {meditationMatches_1.length} ({(meditationMatches_1.length < 1) ? 0 : Math.floor((meditationMatches_1.length / AssaultCount) * 100)}%) Matches, Bought Meditation First</div>
                    {(meditationMatches_1.length < 1) ? "" : <div>
                        <div>Average amount of Self Healing: {Math.floor((MedMatches_1_SelfHeal / meditationMatches_1.length)).toLocaleString()}</div>
                        <div>Average amount of Team Healing: {Math.floor((MedMatches_1_OtherHeal / meditationMatches_1.length)).toLocaleString()}</div>
                        <div>Won {Med_1Wins.length} ({Math.floor((Med_1Wins.length / meditationMatches_1.length) * 100)}%) of these Matches</div></div>}
                    <br></br>
                    <div>In {meditationMatches_2.length} ({(meditationMatches_2.length < 1) ? 0 : Math.floor((meditationMatches_2.length / AssaultCount) * 100)}%) Matches, Bought Meditation Second</div>
                    {(meditationMatches_2.length < 1) ? "" : <div>
                        <div>Average amount of Self Healing: {Math.floor((MedMatches_2_SelfHeal / meditationMatches_2.length)).toLocaleString()}</div>
                        <div>Average amount of Team Healing: {Math.floor((MedMatches_2_OtherHeal / meditationMatches_2.length)).toLocaleString()}</div>
                        <div>Won {Med_2Wins.length} ({Math.floor((Med_2Wins.length / meditationMatches_2.length) * 100)}%) of these Matches</div></div>}
                    <br></br>
                    <div>In {noMedMatches.length} ({(noMedMatches.length < 1) ? 0 : Math.floor((noMedMatches.length / AssaultCount) * 100)}%) Matches, Did Not buy Meditation at All</div>
                    {(noMedMatches.length < 1) ? "" : <div>
                        <div>Average amount of Self Healing: {Math.floor((noMed_SelfHeal / noMedMatches.length)).toLocaleString()}</div>
                        <div>Average amount of Team Healing: {Math.floor((noMed_OtherHeal / noMedMatches.length)).toLocaleString()}</div>
                        <div>Won {noMedWins.length} ({Math.floor((noMedWins.length / noMedMatches.length) * 100)}%) of these Matches</div></div>}
                    <br>
                    </br><button className="buttonReturn" onClick={reset}>Restart</button></div> : ""}
            </div></div>
            )
        }
    }

    function NameBox() {
        function handleSubmit(e) {
            e.preventDefault()
            const name = e.target.SName.value
            setUserInputName(name)
        }
        return (
            <div className={(userData_API.name) ? "form disable" : "form"}>
                <form name="smite_Name" onSubmit={handleSubmit}>
                    <div className="inputBox"><input type="text" name="SName" id="smitename" placeholder="AmishInsurgent" /></div>
                    <div className="inputInfo">Enter your Smite Username
                        and be Judged</div>
                    <br />
                    <div className={(playerDoesntExist) ? "notPlayer" : "disable"}>Not a Player, Try Again</div>
                </form>
            </div>
        )
    }
    //here we govelvelvelvel
    return (
        <div className="main">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"/> 
                <title>Meditation | Loomer.dev</title>
            </Helmet>
            <div className="cf">
                <img className={!(resultScreen) ? "bottom" : "bottom shiftleft"}
                    src={!(resultScreen) ? med_dark : (resultScreen === 1) ? teamplayer_dark : selfish_dark}
                    alt="selfish_player_image" 
                />
                <img className={!(resultScreen) ? "top" : "top shiftleft"}
                    src={!(resultScreen) ? top_med : (resultScreen === 1) ? teamplayer : selfish}
                    alt="selfish_player_image" 
                />
            </div>
            <div className="medImage">
                <div className={(userDataExists) ? 'confirmation FadeInAndOut' : 'confirmation visible'}>
                    <ConfirmationBox />
                </div>
                <div className={(userDataExists) ? 'confirmationtwo FadeInAndOutDelay' : 'confirmationtwo visible'}>
                    <PatheticBox />
                </div>
                <div className={(resultScreen) ? "Summary" : "disable"}>
                    <div className={(resultScreen) ? "healpulse" : "disable"}></div>
                    <UserDetail />
                </div>
                <div className="inputClass">
                    <NameBox />
                </div>
            </div>
            <div className={(userDataExists) ? `cover FadeInAndOutDelayExtra ${(fadeState) ? 'disable' : ''}` : 'cover disable'}></div>
        </div>
    );
}

export default Meditation;